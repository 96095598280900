import React from "react";
import { SEO } from "../components/SEO";

const Contact = (): React.ReactElement => {
  return (
    <>
      <SEO title="Contact" metaDescription="Contact" />

      <p className="m-3">
        Natürlich können Sie auch alles vor Ort begutachten.
      </p>
      <div className="flex flex-col lg:flex-row justify-between m-3">
        <div>
          <p className="mt-2">
            Kim Ohlsen
            <br /> Schiftenweg 1<br /> 24887 Silberstedt / Hünning
          </p>
          <p className="mt-2">
            <b>Telefon </b> +49 4625 / 82 26 858
            <br />
            <strong>Mobil </strong>+49 162 65 68 406
            <br />
            <strong>E-Mail</strong>{" "}
            <a href="mailto:info@schmiedekunst-ohlsen.com">
              info@schmiedekunst-ohlsen.com
            </a>
          </p>
        </div>

        <div className="mt-2">
          <iframe
            className=""
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4625.848593880456!2d9.329529877637578!3d54.570085905345046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b37a9d6924e637%3A0xa864a70157a16a7c!2sSchiftenweg+1%2C+24887+Silberstedt!5e0!3m2!1sde!2sde!4v1429094694011"
            width="600"
            height="450"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
